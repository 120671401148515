import {
  Container,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../components/Breadcrumbs"
import HelpForm from "../components/HelpForm"
import { useCurrentUser } from "../api/useCurrentUser"
import { useDevice } from "../api/useDevices"
import { dvrDescription, staffEngagementDescription } from "../lib/constants"
import LoadingScreen from "./LoadingScreen"

export function DeviceScreen() {
  const { deviceId } = useParams()
  const { device, isLoading: deviceLoading } = useDevice(deviceId)
  const { user, company, isLoading: userLoading } = useCurrentUser()
  const isDesktop = useBreakpointValue([false, null, null, true])

  if (userLoading || deviceLoading) {
    return <LoadingScreen />
  }

  const pedVariables = JSON.stringify({
    selected_device_ids: [device.id],
  })
  const vehVariables = JSON.stringify({
    selected_device_ids: [device.id],
  })

  const backgroundImage = isDesktop
    ? "/images/desktop-doorcounts-blur.jpg"
    : "/images/mobile-doorcounts-blur.jpg"

  return (
    <Stack p={5}>
      <Breadcrumbs
        links={[
          { to: "/devices", name: "All Devices" },
          { to: `/devices/${deviceId}`, name: device.display_name, isCurrentPage: true },
        ]}
      />

      <Tabs defaultIndex={0} isLazy lazyBehavior="keepMounted">
        <TabList>
          <Tab>Pedestrians</Tab>
          <Tab>Vehicles</Tab>
          <Tab>Staff Engagement</Tab>
          <Tab>Video Archive</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={0} py={1}>
            <explo-dashboard
              dash-user-group-token={`mXyAZrbAdl:${company.user_group_token}`}
              isProduction="true"
              environment="production"
              variables={pedVariables}
            ></explo-dashboard>
          </TabPanel>
          <TabPanel px={0} py={1}>
            <explo-dashboard
              dash-user-group-token={`zvgAGRp1Kl:${company.user_group_token}`}
              isProduction="true"
              environment="production"
              variables={vehVariables}
            ></explo-dashboard>
          </TabPanel>

          <TabPanel px={0} py={1}>
            <Stack
              p={10}
              backgroundImage={
                "linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('" +
                backgroundImage +
                "')"
              }
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              height="100vh"
            >
              <Container
                backgroundColor="#FAFAFA"
                centerContent
                padding="8"
                borderRadius="25px"
                width="unset"
              >
                <HelpForm
                  title="Upgrade needed!"
                  description={staffEngagementDescription}
                  defaultValues={{
                    message: `I'd like to learn more about Staff Engagement Tracking.`,
                    email: user?.email,
                    phone: user?.phone,
                  }}
                  helpContext="Staff Engagement Upgrade"
                />
              </Container>
            </Stack>
          </TabPanel>
          <TabPanel px={0} py={1}>
            <Stack
              p={5}
              backgroundImage={
                "linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('/images/action-gif-low.gif')"
              }
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              height="100vh"
            >
              <Container
                backgroundColor="#FAFAFA"
                centerContent
                padding="8"
                borderRadius="25px"
                width="unset"
              >
                <HelpForm
                  title="Upgrade needed!"
                  description={dvrDescription}
                  defaultValues={{
                    message: `I'd like to learn more about access to my Video Archive.`,
                    email: user?.email,
                    phone: user?.phone,
                  }}
                  helpContext="Video Archive Upgrade"
                />
              </Container>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default DeviceScreen
