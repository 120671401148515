import { Marker } from "@urbica/react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import CFMarker from "./CFMarker"

function CFCameraMarker({ longitude, latitude, isOnline, onClick, isSelected }) {
  let borderColor = isOnline ? "rgb(0,200,0)" : "gray"
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <CFMarker
        onClick={onClick}
        sx={{
          borderWidth: isSelected ? 3 : 2,
          boxShadow: isSelected ? "3px 3px 3px 1px rgba(0, 0, 0, 0.4)" : "none",
          borderColor,
          transform: isSelected ? "scale(1.5)" : "scale(1)",
        }}
      >
        <img src="/cf-icon-small.png" alt="curbFlow Logo" style={{ width: "100%" }} />
      </CFMarker>
    </Marker>
  )
}

export default CFCameraMarker
