import useWindowSize from "../lib/useWindowSize"
import "mapbox-gl/dist/mapbox-gl.css"
import React, { useEffect } from "react"
import { Button, IconButton } from "@chakra-ui/react"
import { CloseIcon } from "@chakra-ui/icons"
import { useHelpContext } from "../components/HelpModal"

const SelectedWindowTease = ({ windowTease, onClose }) => {
  const windowSize = useWindowSize()

  const width = Math.min(windowSize.width * 0.8, 1200)

  useEffect(() => {
    var options = {
      url: windowTease["Video Link"],
      width: width - 28,
      loop: true,
    }

    var player = new window.Vimeo.Player("windowtease-video-embed", options)

    player.setVolume(0)
  })

  return (
    <div
      style={{
        border: "solid 1px darkgray",
        padding: 14,
        position: "absolute",
        left: "50%",
        top: "50%",
        backgroundColor: "white",
        borderRadius: 4,
        maxWidth: width,
        boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
      }}
    >
      <h3 style={{ textAlign: "center" }}>{windowTease["Property Address"]}</h3>
      <p style={{ textAlign: "center" }}>
        {new Date(windowTease["Date/Time Visited"]).toLocaleString()}
      </p>
      <div id="windowtease-video-embed"></div>
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{
          position: "absolute",
          top: 2,
          right: 2,
        }}
      >
        <CloseIcon />
      </IconButton>

      <CurbCarCta />
    </div>
  )
}

const CurbCarCta = () => {
  const helpContext = useHelpContext()
  return (
    <div
      style={{
        position: "absolute",
        top: 110,
        right: 25,
        left: "auto",
        bottom: "auto",
        border: "none",
        backgroundColor: "rgba(255, 255, 255, 0.80)",
        borderRadius: 5,
        textAlign: "center",
        padding: 10,
      }}
    >
      <p>Want more information from this location?</p>
      <Button
        onClick={() => {
          helpContext.showModal("curbCar")
        }}
      >
        Ask about curbCar
      </Button>
    </div>
  )
}

export default SelectedWindowTease
