import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from "@chakra-ui/react"
import React from "react"

type Props = {
  placeholder?: string
  name?: string
  onChange?: any
  onBlur?: any
  value?: any
  isLoading?: boolean
}

const SearchInput = React.forwardRef((props: Props, ref: any) => {
  const { placeholder, name, value, onChange, onBlur, isLoading } = props
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
      <Input
        backgroundColor="white"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        ref={ref}
      />
      {isLoading && (
        <InputRightElement pointerEvents="none" children={<Spinner size="sm" color="blue.500" />} />
      )}
    </InputGroup>
  )
})

export default SearchInput
