import { Stack, Text, Link, Box, Container } from "@chakra-ui/react"
import React from "react"
import HelpForm from "../components/HelpForm"
import { useCurrentUser } from "../api/useCurrentUser"
import LoadingScreen from "./LoadingScreen"

export function CurbcarScreen() {
  const { user, isLoading } = useCurrentUser()
  if (isLoading) return <LoadingScreen />
  return (
    <Stack p={5}
      backgroundImage={"linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('/images/action-gif-low.gif')"}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height="100%"
    >
      <Container backgroundColor="#FAFAFA" centerContent padding='8' borderRadius="25px" width="unset">
        <HelpForm
          title="Learn About curbCar"
          description={
            <Box>
              <Text>Dispatch a curbCar to count pedestrians and vehicles at any location</Text>
              <Text>
                <Link color="blue.500" href="https://www.curbflow.com/curbcar" isExternal>
                  Learn more about curbCar here
                </Link>
              </Text>
            </Box>
          }
          helpContext="curbCar"
          defaultValues={{
            message: `I'd like to learn more about curbCar!`,
            email: user?.email,
            phone: user?.phone,
          }}
        />
      </Container>
    </Stack>
  )
}

export default CurbcarScreen
