import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Stack,
} from "@chakra-ui/react"
import React from "react"
import ChangeEmailForm from "../forms/ChangeEmailForm"
import ChangePasswordForm from "../forms/ChangePasswordForm"
import EditProfileForm from "../forms/EditProfileForm"
import { useCurrentUser } from "../api/useCurrentUser"
import LoadingScreen from "./LoadingScreen"

export function AccountScreen() {
  const { user, isLoading } = useCurrentUser()
  if (isLoading) return <LoadingScreen />

  return (
    <Stack p={5}>
      <Accordion defaultIndex={0}>
        <AccordionItem>
          <AccordionButton>
            <Heading fontWeight="300" fontSize="2xl" flex="1" textAlign="left">
              Your Information
            </Heading>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <EditProfileForm onSubmit={() => {}} defaultValues={user} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          {/* <Heading>
            <AccordionButton>
              <Heading fontWeight="300" fontSize="2xl" flex="1" textAlign="left">
                Change Email
              </Heading>
              <AccordionIcon />
            </AccordionButton>
          </Heading> */}
          <AccordionPanel pb={4}>
            <ChangeEmailForm onSubmit={() => {}} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Heading fontWeight="300" fontSize="2xl" flex="1" textAlign="left">
              Change Password
            </Heading>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <ChangePasswordForm onSubmit={() => {}} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  )
}

export default AccountScreen
