import axios from "axios"
import { useCallback, useReducer } from "react"
import config from "../config"

export function searchMapboxPlaces(query, proximity?, bbox?, types?) {
  return axios.get(
    `https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json`,
    {
      params: {
        access_token: config.env.MAPBOX_ACCESS_TOKEN,
        proximity,
        bbox,
        types,
      },
    }
  )
}

const initialState = {
  isLoading: false,
  showResults: false,
  error: null,
  selectedResult: null,
  results: [],
  query: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_QUERY":
      return { ...state, query: action.value }
    case "START_FETCH_RESULTS":
      return { ...state, error: null, isLoading: true }
    case "END_FETCH_RESULTS":
      const results = action?.result?.data?.features || []
      return {
        ...state,
        error: null,
        isLoading: false,
        results,
        showResults: true,
      }
    case "SELECT_RESULT":
      return {
        ...state,
        showResults: false,
        selectedResult: action.result,
        query: action.result.place_name,
      }
    case "CLEAR_RESULTS":
      return {
        ...state,
        showResults: false,
        results: [],
        selectedResult: null,
      }
    case "ERROR":
      return { ...state, error: action.message, isLoading: false }
    default:
      return state
  }
}

type Props = {
  proximity?: string
}
function useMapboxSearch(props?: Props) {
  const proximity = props?.proximity
  const [state, dispatch] = useReducer(reducer, initialState)
  const onSearchChange = useCallback(
    async (e) => {
      const value = e.target.value
      dispatch({ type: "SET_QUERY", value })
      if (value) {
        try {
          dispatch({ type: "START_FETCH_RESULTS" })
          const result = await searchMapboxPlaces(value, proximity)
          dispatch({ type: "END_FETCH_RESULTS", result })
        } catch (e) {
          dispatch({ type: "ERROR", message: e.message })
        }
      } else {
        dispatch({ type: "CLEAR_RESULTS" })
      }
    },
    [dispatch, proximity]
  )

  const onSelectResult = useCallback(
    (result) => {
      dispatch({ type: "SELECT_RESULT", result })
    },
    [dispatch]
  )

  return {
    ...state,
    onSearchChange,
    onSelectResult,
  }
}

export default useMapboxSearch
