import { useQuery } from "react-query"
import axios from "axios"

const fetchStats = async () => {
  const result = await axios.get(
    "https://s3.amazonaws.com/apps.curbflow.com/weekly_stats/latest.json"
  )
  return result.data
}

const useDeviceStats = () => {
  const query = useQuery([`use-stats`], fetchStats)
  return query
}

export default useDeviceStats
