import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { FieldError } from "react-hook-form";

type SelectOption = { label: any; value: any; disabled?: boolean };
type Props = {
  helpText?: string;
  label: string;
  name: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  error?: FieldError;
  options: SelectOption[];
  value?: string;
};

const SelectFormControl = React.forwardRef((props: Props, ref: any) => {
  const {
    helpText,
    label,
    error,
    name,
    onChange,
    onBlur,
    placeholder,
    options,
    value,
  } = props;
  let selectOptions = options || [];
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
      >
        {selectOptions.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          );
        })}
      </Select>
      <FormErrorMessage>{error?.message || error?.type}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
});

export default SelectFormControl;
