import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack } from "@chakra-ui/react"
import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"

type Link = {
  to: string
  name: string
  isCurrentPage?: boolean
}

type Props = {
  links: Link[]
}

export function Breadcrumbs({ links }: Props) {
  return (
    <Stack>
      <Breadcrumb>
        {links.map((link, i) => {
          return (
            <BreadcrumbItem key={i} isCurrentPage={link.isCurrentPage}>
              <BreadcrumbLink to={link.to} as={ReactRouterLink} fontSize="2xl" color="blue.500">
                {link.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
    </Stack>
  )
}

export default Breadcrumbs
