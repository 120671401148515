import { Stack } from "@chakra-ui/react"
import React from "react"
import HelpForm from "../components/HelpForm"
import { useCurrentUser } from "../api/useCurrentUser"
import LoadingScreen from "./LoadingScreen"

export function SupportScreen() {
  const { user, isLoading } = useCurrentUser()
  if (isLoading) return <LoadingScreen />

  return (
    <Stack p={5} maxW="700px">
      <HelpForm
        title="Contact Us"
        description="Feel free to reach out with any questions, comments or concerns"
        slackChannel="curbcar-support"
        defaultValues={{
          message: "",
          email: user?.email,
          phone: user?.phone,
        }}
      />
    </Stack>
  )
}

export default SupportScreen
