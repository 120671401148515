import { Box, Button, Text } from "@chakra-ui/react"
import { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../datepicker-styles.css"

export const DateRangePicker = ({ initialStart, initialEnd, onChange }) => {
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)

  return (
    <Box>
      <Box flexDirection="row" display="flex" maxWidth={600}>
        <Box flex={1} mr={2}>
          <Text>Start</Text>
          <DatePicker
            selected={start}
            onChange={(update) => {
              setStart(update)
            }}
            isClearable={true}
            showTimeSelect={true}
            dateFormat="Pp"
          />
        </Box>
        <Box flex={1} ml={2}>
          <Text>End</Text>
          <DatePicker
            selected={end}
            onChange={(update) => {
              setEnd(update)
            }}
            isClearable={true}
            showTimeSelect={true}
            dateFormat="Pp"
          />
        </Box>
      </Box>
      <Button
        mt={3}
        mb={3}
        colorScheme="blue"
        onClick={() => {
          const params = {
            startDate: start,
            endDate: end,
          }
          onChange(params)
        }}
      >
        Set Data Query Range
      </Button>
    </Box>
  )
}
