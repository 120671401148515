const point = (coordinates, properties = {}) => ({
  type: "Feature",
  properties,
  geometry: {
    type: "Point",
    coordinates,
  },
})

export default point
