import { Box, Container, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { useCurrentUser } from "../api/useCurrentUser"
import HelpForm from "../components/HelpForm"
import LoadingScreen from "./LoadingScreen"

export function ParkingScreen() {
  const { user, company, isLoading } = useCurrentUser()
  if (isLoading) return <LoadingScreen />

  const parkingEventsEnabled = ["1JAPBK3U","U9ACJ25D"].includes(company?.id)

  if (parkingEventsEnabled) {
    return (
      <Stack p={5}>
        <ParkingEventDashboard company={company} />
      </Stack>
    )
  } else {
    return (
      <Stack
        p={5}
        backgroundImage={
          "linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('/images/action-gif-low.gif')"
        }
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        height="100%"
      >
        <Container
          backgroundColor="#FAFAFA"
          centerContent
          padding="8"
          borderRadius="25px"
          width="unset"
        >
          <HelpForm
            title="Learn more about Parking Events"
            description={
              <Box>
                <Text>
                  Contact us to discuss using curbFlow to track legal vs illegal parking events at
                  your locations
                </Text>
              </Box>
            }
            helpContext="curbCar"
            defaultValues={{
              message: `I'd like to learn more about parking event tracking from curbFlow.`,
              email: user?.email,
              phone: user?.phone,
            }}
          />
        </Container>
      </Stack>
    )
  }
}

function ParkingEventDashboard({ company }) {
  return (
    <Box>
      <explo-dashboard
        dash-user-group-token={`QjDxe7wALG:${company.user_group_token}`}
        isProduction="true"
        environment="production"
        style={{ minWidth: 600 }}
      ></explo-dashboard>
    </Box>
  )
}

export default ParkingScreen
