import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  styles: {
    global: {
      ".active-nav-link": {
        backgroundColor: "blue.600",
      },
    },
  },
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat'
  }
})

export default theme
