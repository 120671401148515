const defaultCoords = {
  dc: {
    viewport: {
      latitude: 38.89116436,
      longitude: -77.03740153,
      zoom: 12,
      bearing: 0,
    },
    maxMapBounds: [
      [-77.25, 38.77],
      [-76.84, 39.07],
    ],
  },
  nyc: {
    viewport: {
      latitude: 40.75,
      longitude: -73.96,
      zoom: 12.5,
      bearing: 0,
    },
    maxMapBounds: [
      [-74.5, 40.275],
      [-73.554, 41.03],
    ],
  },
}

export default defaultCoords
