import { Stack, Text, Box } from "@chakra-ui/react"
import React from "react"
import LinkStyledButton from "../components/LinkStyledButton"
import useCompanies from "../api/useCompanies"
import { useLoginAs } from "../api/useCurrentUser"
import LoadingScreen from "./LoadingScreen"

export function SelectCompanyScreen() {
  const { companies, devicesForCompany, isLoading } = useCompanies()
  const [loginAs, isChangingUser] = useLoginAs()

  if (isLoading || isChangingUser) {
    return <LoadingScreen />
  }

  return (
    <Stack p={5}>
      <Text>All Companies</Text>
      {companies.map((company) => {
        const deviceCount = (devicesForCompany[company.id] || []).length
        return (
          <Box key={company.id}>
            <Text fontSize="2xl">
              <LinkStyledButton
                onClick={() => {
                  loginAs(company.id)
                }}
              >
                {company.name} - ({deviceCount})
              </LinkStyledButton>
            </Text>
            <Text fontSize="xs" fontWeight="300">
              {company.id}
            </Text>
          </Box>
        )
      })}
    </Stack>
  )
}

export default SelectCompanyScreen
