import { Box, Flex, Image, Text } from "@chakra-ui/react"
import React from "react"

type Props = {
  image?: {
    url: string
    last_modified: string
  }
}

export function DeviceImage({ image }: Props) {
  return (
    <Box position="relative" pb={1}>
      {image && <Image src={image?.url} borderRadius="base" />}
      {!image && <ImageMissing />}
      <Text fontSize="xs" color="#7BF03A" position="absolute" bottom={1} right={1}>
        {image?.last_modified}
      </Text>
    </Box>
  )
}

const ImageMissing = () => {
  return (
    <Flex
      width="100%"
      minHeight="100px"
      align="center"
      justify="center"
      backgroundColor="gray.50"
      borderRadius="base"
      sx={{
        aspectRatio: "16/9",
      }}
    >
      <Text textAlign="center">Device Image Not Available</Text>
    </Flex>
  )
}

export default DeviceImage
