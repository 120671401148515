import { useCallback, useEffect, useState } from "react"
import LoadingScreen from "./screens/LoadingScreen"
import axios from "./lib/axios"

async function checkLoginStatus() {
  try {
    const result = await axios.get("/api/v2/me")
    return result?.data?.user ? true : false
  } catch (e) {
    return false
  }
}

export function RequireAuth({ children }) {
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isInitialized, setInitialized] = useState(false)

  const checkAuth = useCallback(async () => {
    setLoading(true)
    const status = await checkLoginStatus()
    setAuthenticated(status)
    setLoading(false)
    setInitialized(true)
  }, [])

  useEffect(() => {
    checkAuth()
    if (process.env.NODE_ENV === "development") {
      console.log("skipping auth check in dev env")
    } else {
      const interval = setInterval(checkAuth, 20000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [checkAuth])

  if (isAuthenticated) {
    return children
  } else if (isInitialized && !isLoading && !isAuthenticated) {
    window.location.replace("/logout")
    return null
  } else {
    return <LoadingScreen />
  }
}
