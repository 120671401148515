import { Stack } from "@chakra-ui/react"
import LoadingSpinner from "../components/LoadingSpinner"

const LoadingScreen = () => {
  return (
    <Stack w="100%" height="100vh" align="center" justify="center">
      <LoadingSpinner />
    </Stack>
  )
}

export default LoadingScreen
