import { Button, chakra, HStack, Stack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import TextFormControl from "./controls/TextFormControl"

export type FormData = {
  email: string
}

type Props = {
  onSubmit: (data: FormData) => void
}

export default function ChangeEmailForm({ onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({})

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} w="100%">
      <Stack spacing={3} align="flex-start" maxWidth="container.md">
        <TextFormControl error={errors?.email} label="New Email" {...register("email", {})} />
        <HStack>
          <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
            Update Email
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  )
}
