import { Box, Button, Text } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import useMapboxSearch from "../api/useMapboxSearch"
import SearchInput from "../forms/controls/SearchInput"

type Props = {
  proximity?: string
  onSelect?: (value) => void
}

export function Geocoder({ proximity, onSelect }: Props) {
  const {
    results,
    isLoading,
    error,
    query,
    onSearchChange,
    onSelectResult,
    selectedResult,
    showResults,
  } = useMapboxSearch({ proximity })

  const selectCB = useRef(onSelect)
  selectCB.current = onSelect

  useEffect(() => {
    selectCB.current && selectCB.current(selectedResult)
  }, [selectedResult])

  return (
    <Box position="relative" minWidth="400px">
      <SearchInput value={query} onChange={onSearchChange} isLoading={isLoading} />
      {showResults && <Results results={results} onSelect={onSelectResult} />}
      {error && <Text color="red.500">{error}</Text>}
    </Box>
  )
}

function Results({ results, onSelect }) {
  return (
    <Box position="relative" bottom="-3px" backgroundColor="rgba(255,255,255,0.8)" zIndex={5}>
      {results.map((result) => (
        <Box p={2} key={result.id}>
          <Button
            width="100%"
            textAlign="left"
            display="block"
            px="5"
            onClick={() => {
              onSelect(result)
            }}
          >
            {result?.place_name}
          </Button>
        </Box>
      ))}
    </Box>
  )
}

export default Geocoder
