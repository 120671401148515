import {
  Box,
  Center,
  Checkbox,
  Container,
  Flex,
  Heading,
  Spinner,
  Square,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import HelpForm from "../components/HelpForm";
import { useCurrentUser } from "../api/useCurrentUser";

import LoadingScreen from "./LoadingScreen";
import axios from "axios";
import { DateTime } from "luxon";

import { Line } from 'react-chartjs-2';
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";
import 'chartjs-adapter-luxon';

//import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
//import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker/dist/entry.nostyle';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { Route, Routes, Navigate, useSearchParams, createSearchParams } from "react-router-dom";


export function RealtimeOccupationScreen() {
  const { user, company, isLoading: userLoading } = useCurrentUser()
  const isDesktop = useBreakpointValue([false, null, null, true])

  if (userLoading) {
    return <LoadingScreen />
  }

  const companies = [
    {id: '4XD1S3V8', timezone: 'US/Mountain'},
    {id: 'DCZS0J87', timezone: 'America/Chicago'},
    {id: '76RU4GED', timezone: 'America/New_York'}
  ]


  const paidFeatureEnabled = companies.find(c => c.id === company?.id);
  const selectedTimezone = companies.find(c => c.id === company?.id)?.timezone;

  const backgroundImage = isDesktop
    ? "/images/desktop-doorcounts-blur.jpg"
    : "/images/mobile-doorcounts-blur.jpg"

  if (paidFeatureEnabled) {
    return (
      <Stack p={5}>
        <RealtimeOccupationDashboard company={company} selectedTimezone={selectedTimezone} />
      </Stack>
    )
  } else {
    return (
      <Stack
        p={5}
        backgroundImage={
          "linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('" +
          backgroundImage +
          "')"
        }
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        height="100%"
      >
        <Container
          backgroundColor="#FAFAFA"
          centerContent
          padding="8"
          borderRadius="25px"
          width="unset"
        >
          <HelpForm
            title="Upgrade needed!"
            description={""}
            defaultValues={{
              message: `I'd like to learn more about enabling Realtime Occupation on my devices.`,
              email: user?.email,
              phone: user?.phone,
            }}
            helpContext="Realtime Occupation Upgrade"
          />
        </Container>
      </Stack>
    )
  }
}

type Device = {
  id: string;
  name: string;
  displayed: boolean,
  qaDisplayed: boolean
}

type Devices = Record<string, Device>;
type DevicesArray = [Device?];

const scanDevices = function ({data, displayedIds, initialized}): Record<string, Device> {
  let devices: Devices = {};
  let devicesIds: [string?] = [];
  let initFirstDisplay = false;
  // try to select first one
  if(!initialized && displayedIds.length === 0){
    initFirstDisplay = true;
  }
  data.forEach((x, index)=> {
    if(!devicesIds.find(el => el === x.device_id)){
      devicesIds.push(x.device_id);

      let displayed = displayedIds.find(id => id===x.device_id)?true:false;
      if(initFirstDisplay && index === 0){
        displayed = true;
      }

      devices[x.device_id] = {
        id: x.device_id,
        name: x.device_display_name_current,
        displayed: displayed,
        qaDisplayed: false
      };
    }
  });

  return devices;
}

function RealtimeOccupationDashboard({ company, selectedTimezone }) {

  Chart.register(CategoryScale);
  const { user, isLoading } = useCurrentUser();

  const userAdminRole = 'SUPER_ADMIN';

  const [initialData, setInitialData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const [refresh, setRefresh] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [occupationCount, setOccupationCount] = useState(0);


  // params
  const [searchParams, setSearchParams] = useSearchParams();

  // dates
  let defaultStart = DateTime.now().startOf('day');
  let defaultEnd = DateTime.now().endOf('day');
  if(searchParams.get('start')){
    defaultStart = DateTime.fromISO(searchParams.get('start'));
  }
  if(searchParams.get('end')){
    defaultEnd = DateTime.fromISO(searchParams.get('end'));
  }
  const [start_date, setStartDate] = useState<DateTime>(defaultStart);
  const [end_date, setEndDate] = useState<DateTime>(defaultEnd);
  const [selectedTimeRange, setSelectedTimeRangeRoot] = useState([defaultStart.toJSDate(), defaultEnd.toJSDate()]);


  // devices
  const [devices, setDevices] = useState<Devices>({});





  const setSelectedTimeRange = (input) => {
    if(!input) return;

    let start = DateTime.fromJSDate(input[0]);
    let end = DateTime.fromJSDate(input[1]);

    const diff = end.diff(start, ["days"])

    if (Math.abs(diff.days) > 7) {
      end = start.plus({ days: 6 }).endOf('day')
    }
    setSelectedTimeRangeRoot([start.toJSDate(), end.toJSDate()]);
  }

  const setDeviceDisplay = ({device, displayed, qaDisplayed}: {device: Device, displayed?: boolean, qaDisplayed?: boolean}) => {
    setDevices({
      ...devices,
      [device.id]: {
        ...device,
        ...(displayed === undefined ? {} : {displayed:displayed} ),
        ...(qaDisplayed === undefined ? {} : {qaDisplayed:qaDisplayed} )
      }
    });
  }

  const timeOffset = DateTime.now().setZone(selectedTimezone).offset - DateTime.now().setZone('system').offset;

  const processGraph = () => {

    let globalOccupation = 0;


    let graphD = [];
    //let graphD = selectedDevices.map((x) => {
    for (const [deviceId, x] of Object.entries(devices)) {

      const filteredData = initialData.filter((d) => d.device_id === x.id);
      let occupation = 0;
      let occupationTrue = 0;
      let ingress = [];
      let egress = [];
      let graphEntry = [];
      let graphEntryTrue = [];

      filteredData.forEach((x) => {
        if (x.ingress_egress_type === 'enter') {
          ingress.push(x);
          occupation += 1;
          occupationTrue += 1;
        }
        if (x.ingress_egress_type === 'exit') {
          egress.push(x);
          occupation -= 1;
          occupationTrue -= 1;
        }

        const d = DateTime.fromMillis(x.time_utc);
        if (occupation < 0) occupation = 0;

        // midnight reset
        let lastEntry = graphEntry.at(-1);
        if(lastEntry) {
          if(lastEntry.d.setZone(selectedTimezone).startOf("day") < d.setZone(selectedTimezone).startOf("day")) {
            //console.log('midnight reset detected');
            const midnightDate = lastEntry.d.setZone(selectedTimezone).plus({days: 1}).startOf("day");
            occupation = 0;
            occupationTrue = 0;
            graphEntry.push({ x: midnightDate.plus({ minutes: timeOffset }), y: occupation, d: midnightDate  });
            graphEntryTrue.push({ x: midnightDate.plus({ minutes: timeOffset }), y: occupationTrue, d: midnightDate  });
          }
        }
        graphEntry.push({ x: d.plus({ minutes: timeOffset }), y: occupation, d: d  });
        graphEntryTrue.push({ x: d.plus({ minutes: timeOffset }), y: occupationTrue, d: d });
      });
      globalOccupation += occupation;


      graphD.push({
        label: x.name,
        data: graphEntry,
        hidden: !x.displayed,
        pointStyle: false,
        cubicInterpolationMode: 'monotone',
        tension: 0.5
      })

      if (user && user.role === userAdminRole) {
        graphD.push({
          label: `${x.name} - QA`,
          data: graphEntryTrue,
          hidden: !x.qaDisplayed,
          pointStyle: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.5
        })
      }
    }

    setOccupationCount(globalOccupation);
    setGraphData(graphD);
  }

  useEffect(() => {
    processGraph();
  }, [devices])





  useEffect(() => {
    setLoading(true);
    axios
      .get("https://rin7xeup4q.us-east-1.awsapprunner.com/api/ingress_egress_events", {params:{
          customer_id: company.id,
          start_date: start_date.valueOf(),
          end_date: end_date.valueOf()
        }})
      .then(function (response) {
        let devices = scanDevices({ data: response.data, initialized: initialized, displayedIds: searchParams.getAll('devices') });
        if(!initialized) setInitialized(true);

        setDevices(devices);
        setInitialData(response.data.sort((a, b) => a.time_utc - b.time_utc));

      })
      .then(function() {
        setLoading(false);
      })
  }, [refresh])



  useEffect(() => {
    let displayedDevices = [];
    for (const [deviceId, x] of Object.entries(devices)) {
      if(x.displayed) displayedDevices.push(deviceId);
    }
    if(displayedDevices.length === 0){
      displayedDevices = searchParams.getAll('devices');
    }
    /*if(displayedDevices.length === 0){
      const firstId = Object.keys(devices).at(0);
      if(firstId) displayedDevices.push(firstId);
    }*/

    setSearchParams(createSearchParams({
      devices: displayedDevices,
      start: start_date.toISO(),
      end: end_date.toISO()}))
  }, [devices, start_date, end_date])

  useEffect(() => {
    setStartDate( DateTime.fromJSDate(selectedTimeRange[0]).startOf('day').setZone(selectedTimezone) );
    setEndDate( DateTime.fromJSDate(selectedTimeRange[1]).endOf('day').setZone(selectedTimezone) );
    setRefresh(refresh+1);
  }, [selectedTimeRange])


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        type: 'time' as const,
        ticks: {
          major: {
            enabled: true
          }
        },
        time: {
          unit: 'hour' as const,
          displayFormats: {
            minute: 'ccc d, HH:mm',
            hour: 'ccc d, HH:00'
          }
        }
      }
    }
  };

  const data = {
    title: {
      text: ""
    },
    datasets: graphData
  };


  // @ts-ignore
  return (
    <Tabs defaultIndex={0} p={0} isLazy lazyBehavior="keepMounted">
      <TabList>
        <Tab>Overview</Tab>
      </TabList>
      <TabPanels p={0}>
        <TabPanel px={0} py={1}>
          <div style={{paddingTop: "2em", height: "3em"}}>
            {!loading && <h2><b>Occupation: {occupationCount}</b></h2>}
            {loading && <h2><Spinner /></h2>}
          </div>
          <div style={{paddingTop: "2em"}}>
            <DateRangePicker onChange={setSelectedTimeRange} value={selectedTimeRange} />
          </div>

          {!loading && graphData.length===0 && <div>
            <h2><i>No data found for selected dates</i></h2>
          </div>}
          {graphData.length>0 && <><div style={{ height: 700 }}>
            <Line options={options} data={{
              datasets: graphData
            }} />
          </div>
          <div>
            <h2>Devices</h2>
            <div>
            <Flex>
              <Box pl={6}>
                <Stack pl={6} mt={1} spacing={1}>
                  {Object.entries(devices).map(([x, device]) => {
                    return <Checkbox
                      key={x}
                      isChecked={device.displayed}
                      onChange={(e) => setDeviceDisplay({displayed: !device.displayed, device: device})}
                    >
                      {device.name}
                    </Checkbox>
                  })}
                </Stack>
              </Box>
              {user && user.role === userAdminRole && <Box pl={6}>
                <Stack pl={6} mt={1} spacing={1}>
                  {Object.entries(devices).map(([x, device]) => {
                    return <Checkbox
                      key={x}
                      isChecked={device.qaDisplayed}
                      onChange={(e) => setDeviceDisplay({qaDisplayed: !device.qaDisplayed, device: device})}
                    >
                      [QA] {device.name}
                    </Checkbox>
                  })}
                </Stack>
              </Box>}
            </Flex></div>
          </div></>}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default RealtimeOccupationScreen