import { ChakraProvider } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "react-query"
import { Route, Routes, Navigate } from "react-router-dom"
import AppLayout from "./layout/AppLayout"
import queryClient from "./queryClient"
import AccountScreen from "./screens/AccountScreen"
import CurbcarScreen from "./screens/CurbcarScreen"
import CurbmapScreen from "./screens/CurbmapScreen"
import DeviceScreen from "./screens/DeviceScreen"
import DevicesScreen from "./screens/DevicesScreen"
import NotFoundScreen from "./screens/NotFoundScreen"
import SupportScreen from "./screens/SupportScreen"
import { HelpContextProvider } from "./components/HelpModal"
import theme from "./theme"
import SelectCompanyScreen from "./screens/SelectCompanyScreen"
import DoorCountsScreen from "./screens/DoorCountsScreen"
import { RequireAuth } from "./Auth"
import ParkingScreen from "./screens/ParkingScreen"
import ParkingScreenTimeline from "./screens/ParkingScreenTimeline"
import RealtimeOccupationScreen from "./screens/RealtimeOccupationScreen"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5cc5cbdf66f84c24a0e6a6a5280db9bd@o268129.ingest.sentry.io/6071333",
  })
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <HelpContextProvider>
          <RequireAuth>
            <AppLayout>
              <Routes>
                <Route path="/devices" element={<DevicesScreen />} />
                <Route path="/devices/:deviceId" element={<DeviceScreen />} />
                <Route path="/door_counts" element={<DoorCountsScreen />} />
                <Route path="/realtime_occupation" element={<RealtimeOccupationScreen />} />
                <Route path="/curbcar" element={<CurbcarScreen />} />
                <Route path="/curbmap" element={<CurbmapScreen />} />
                <Route path="/parking" element={<ParkingScreen />} />
                <Route path="/parking_utilization" element={<ParkingScreenTimeline />} />
                <Route path="/support" element={<SupportScreen />} />
                <Route path="/account" element={<AccountScreen />} />
                <Route path="/admin" element={<SelectCompanyScreen />} />
                <Route path="/" element={<Navigate to="/devices" />} />
                <Route path="/app" element={<Navigate to="/devices" />} />
                <Route path="/v2" element={<Navigate to="/devices" />} />
                <Route path="*" element={<NotFoundScreen />} />
              </Routes>
            </AppLayout>
          </RequireAuth>
        </HelpContextProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App
