import { Badge, Box, HStack, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { BiBarChart, BiCameraHome, BiDoorOpen, BiKey, BiLogOut, BiUserCircle } from "react-icons/bi"
import { MdLocalParking } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { useCurrentUser } from "../api/useCurrentUser"

type NavProps = {
  onClick?: () => void
}
const MainNav = ({ onClick }: NavProps) => {
  const { user, company } = useCurrentUser()

  const isMMS = company?.id === "3CQAHFKP"

  const showAdmin = user?.role === "SUPER_ADMIN"
  return (
    <Stack spacing={0} bg="blue.400" h="100%">
      {!isMMS && (
        <NavButton
          onClick={onClick}
          to="/devices"
          icon={<BiCameraHome color="white" size="28px" />}
        >
          My Devices
        </NavButton>
      )}
      <NavButton
        onClick={onClick}
        to="/door_counts"
        icon={<BiDoorOpen color="white" size="28px" />}
      >
        Door Counts
      </NavButton>
      <NavButton
        onClick={onClick}
        to="/realtime_occupation"
        icon={<BiBarChart color="white" size="28px" />}
      >
        Realtime Occupation
      </NavButton>
      <NavButton
        onClick={onClick}
        to="/parking"
        icon={<MdLocalParking color="white" size="28px" />}
      >
        Parking Events
      </NavButton>
      <NavButton
        onClick={onClick}
        to="/parking_utilization"
        icon={<BiBarChart color="white" size="28px" />}
      >
        Parking Utilization
      </NavButton>
      {false && (
        <NavButton
          onClick={onClick}
          to="/account"
          icon={<BiUserCircle color="white" size="28px" />}
        >
          My Account
        </NavButton>
      )}
      {showAdmin && (
        <NavButton onClick={onClick} to="/admin" icon={<BiKey color="white" size="28px" />}>
          Admin
        </NavButton>
      )}
      <ExtNavLink href="/logout" icon={<BiLogOut color="white" size="28px" />}>
        Logout
      </ExtNavLink>
    </Stack>
  )
}

const ExtNavLink = ({ icon, children, ...rest }) => {
  return (
    <a {...rest}>
      <Box>
        <HStack
          py={4}
          px={3}
          _hover={{ bg: "blue.700" }}
          transition="all ease-in-out 250ms"
          align="center"
        >
          {icon}
          <Text fontSize="md" fontWeight="semibold" color="white">
            {children}
          </Text>
        </HStack>
      </Box>
    </a>
  )
}

type Props = {
  to: string
  exact?: boolean
  children: React.ReactNode
  badgeCount?: number
  icon: React.ReactNode
  onClick?: () => void
}
const NavButton = ({ to, exact, children, badgeCount, onClick, icon }: Props) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? "active-nav-link" : "")}
      onClick={onClick}
    >
      <HStack
        py={4}
        px={3}
        _hover={{ bg: "blue.700" }}
        transition="all ease-in-out 250ms"
        align="center"
      >
        {icon}
        <Text fontSize="md" fontWeight="semibold" color="white">
          {children}
        </Text>
        {badgeCount && (
          <Badge
            ml="1"
            colorScheme="red"
            borderRadius="2em"
            fontSize="md"
            minWidth="1.5em"
            justifyContent="center"
            alignItems="center"
            display="flex"
            fontWeight="semibold"
          >
            {badgeCount.toFixed(0)}
          </Badge>
        )}
      </HStack>
    </NavLink>
  )
}

export default MainNav
