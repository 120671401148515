import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import { Flex, Checkbox, useMediaQuery, chakra, HStack, Text } from "@chakra-ui/react"
import { colors } from "./styles"

function MapLegend({
  onlineDevices,
  totalDevices,
  deviceStatData,
  windowTeasePoints,
  useSatellite,
  setUseSatellite,
}) {
  return (
    <div
      style={{
        border: "solid 1px darkgray",
        padding: 10,
        position: "absolute",
        left: 10,
        top: 55,
        backgroundColor: "white",
        borderRadius: 4,
        fontFamily: "monospace",
        boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
      }}
    >
      <DesktopOnly>
        <Flex marginBottom="2">
          <Legend borderColor="green" />
          <b style={{ marginRight: 5 }}>{onlineDevices}</b> Devices Online
        </Flex>
        <Flex marginBottom="2">
          <Legend borderColor="gray" />
          <b style={{ marginRight: 5 }}>{totalDevices - onlineDevices}</b> Temporarily Offline
        </Flex>
        <Flex marginBottom="2">
          <Legend borderColor="orange" />
          <b style={{ marginRight: 5 }}>
            {deviceStatData && deviceStatData.meta && deviceStatData.meta.pending_deals}
          </b>{" "}
          Pending Activation
          <div style={{ marginBottom: 10 }} />
        </Flex>
        <Flex marginBottom="2">
          <Legend borderColor={colors.palette.blue.main} />
          <b style={{ marginRight: 5 }}>{windowTeasePoints && windowTeasePoints.length}</b> Sample
          Videos
          <div style={{ marginBottom: 10 }} />
        </Flex>
      </DesktopOnly>
      <HStack>
        <Checkbox
          checked={useSatellite}
          onChange={() => {
            setUseSatellite(!useSatellite)
          }}
        >Use satellite</Checkbox>
      </HStack>
    </div>
  )
}

export default MapLegend

const Legend = chakra("div", {
  baseStyle: {
    display: "inline-block",
    width: "1.5em",
    height: "1.5em",
    border: "solid 2px",
    borderRadius: "1.5em",
    marginRight: "0.25em",
  },
})

const DesktopOnly = ({ children }) => {
  const [isLargerThan720] = useMediaQuery("(min-width: 720px)")
  if (isLargerThan720) {
    return children
  }
  return null
}
