import MapGL, { Marker, NavigationControl } from "@urbica/react-map-gl"
import AddressLookup from "./AddressLookup"
import CFCameraMarker from "./CFCameraMarker"
import CFClusterMarker from "./CFClusterMarker"
import CFWindowTeaseMarker from "./CFWindowTeaseMarker"
import MapLegend from "./MapLegend"
import SelectedCamera from "./SelectedCamera"
import SelectedWindowTease from "./SelectedWindowTease"
import config from "../config"
import { format } from "date-fns"
import useCameras from "../api/useCameras"
import useDeviceStats from "../api/useDeviceStats"
import useWindowTease from "../api/useWindowTease"
import "mapbox-gl/dist/mapbox-gl.css"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaMapMarkerAlt } from "react-icons/fa"
import { colors } from "./styles"
import Supercluster from "supercluster"
import devlog from "../lib/devlog"

function CurbMap({ defaultCoords }) {
  const [selectedFeature, setSelectedFeature] = useState(undefined)
  const [useSatellite, setUseSatellite] = useState(false)
  const [addressMarker, setAddressMarker] = useState<any>(null)
  const [features, setFeatures] = useState([])
  const [viewport, setViewport] = useState({
    ...defaultCoords.viewport,
  })

  const mapRef = useRef<any>()

  const { data: deviceStatData } = useDeviceStats()
  const windowTeasePoints = useWindowTease()
  const { onlineDevices, totalDevices, cameraPoints } = useCameras()
  const selectedDevice = selectedFeature && selectedFeature.device ? selectedFeature.device : null
  const selectedWindowTease =
    selectedFeature && selectedFeature.type === "Window Tease" ? selectedFeature : null

  const statsLastUpdated = useMemo(() => {
    if (deviceStatData && deviceStatData["meta"]) {
      return new Date(deviceStatData["meta"]["calculated_at"] * 1000).toLocaleDateString()
    }
    return null
  }, [deviceStatData])

  const selectedStats = useMemo(() => {
    if (!selectedDevice) return {}
    return deviceStatData[selectedDevice.id] || {}
  }, [deviceStatData, selectedDevice])

  const supercluster = useMemo(() => {
    return new Supercluster({
      minZoom: 0,
      maxZoom: 16,
      radius: 35,
      extent: 512,
      nodeSize: 64,
    }).load(cameraPoints)
  }, [cameraPoints])

  const recomputeMapFeatures = useCallback(() => {
    if (!mapRef || !supercluster) return []
    const map = mapRef?.current?.getMap()
    const mapZoom = map?.getZoom()
    const bounds = map?.getBounds()?.toArray() || [[], []]
    const bbox = bounds[0].concat(bounds[1])

    setFeatures(supercluster.getClusters(bbox, Math.round(mapZoom)))
  }, [supercluster, mapRef])

  useEffect(() => {
    recomputeMapFeatures()

    if (mapRef.current.getMap()) {
      const map = mapRef.current.getMap()
      map.on("moveend", recomputeMapFeatures)
      return () => {
        map.off("moveend", recomputeMapFeatures)
      }
    }
  }, [mapRef, recomputeMapFeatures, supercluster])

  const getLeaves = (cluster_id) => {
    try {
      return supercluster.getLeaves(cluster_id, Infinity)
    } catch (e) {
      return []
    }
  }

  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport)
  }, [])

  return (
    <div style={{ padding: 10, position: "relative", height: "100%" }}>
      <div style={{ position: "relative", height: "100%" }}>
        <MapGL
          ref={mapRef}
          maxBounds={defaultCoords.maxMapBounds}
          bearing={viewport.bearing}
          latitude={viewport.latitude}
          longitude={viewport.longitude}
          zoom={viewport.zoom}
          mapStyle={useSatellite ? "mapbox://styles/mapbox/satellite-v9" : undefined}
          accessToken={config.env.MAPBOX_ACCESS_TOKEN}
          viewportChangeOptions={{
            duration: 500,
          }}
          onViewportChange={handleViewportChange}
          style={{
            width: "100%",
            height: "100%",
          }}
          onClick={({ lngLat }) => {
            devlog(lngLat)
          }}
        >
          {windowTeasePoints.map((point, i) => {
            const [longitude, latitude] = point.geometry.coordinates
            return (
              <CFWindowTeaseMarker
                key={point.properties["Property Address"] + i}
                longitude={longitude}
                latitude={latitude}
                onClick={() => {
                  setSelectedFeature(point.properties)
                }}
                isSelected={false}
              />
            )
          })}
          {features.map((feature) => {
            if (feature.properties.cluster) {
              const cluster = feature.properties
              const [longitude, latitude] = feature.geometry.coordinates
              const children = getLeaves(cluster.cluster_id)
              const onlineCount = children.reduce((sum, c) => {
                if (c.properties.device.isOnline) sum += 1
                return sum
              }, 0)
              return (
                <CFClusterMarker
                  key={cluster.cluster_id}
                  longitude={longitude}
                  latitude={latitude}
                  pointCount={feature.properties.point_count}
                  onlineCount={onlineCount}
                  onClick={() => {
                    const zoomLevel = supercluster.getClusterExpansionZoom(cluster.cluster_id)
                    mapRef.current?.getMap()?.flyTo({
                      center: [longitude, latitude],
                      zoom: zoomLevel,
                    })
                  }}
                />
              )
            } else {
              const camera = feature.properties
              return (
                <CFCameraMarker
                  key={camera.id}
                  latitude={camera.latitude}
                  longitude={camera.longitude}
                  isOnline={camera.device.isOnline}
                  onClick={() => setSelectedFeature(camera)}
                  isSelected={selectedFeature?.id === camera.id}
                />
              )
            }
          })}

          {addressMarker && (
            <Marker latitude={addressMarker.center[1]} longitude={addressMarker.center[0]}>
              <FaMapMarkerAlt size={30} color={colors.palette.blue.main} />
            </Marker>
          )}
          <NavigationControl showCompass showZoom position="bottom-right" />
        </MapGL>
        {selectedDevice && (
          <SelectedCamera
            selectedDevice={selectedDevice}
            selectedStats={selectedStats}
            statsLastUpdated={statsLastUpdated}
            dateBounds={getDataDateBounds(deviceStatData)}
            onClose={() => {
              setSelectedFeature(null)
            }}
          />
        )}
        {selectedWindowTease && (
          <SelectedWindowTease
            key={selectedWindowTease["Video Link"]}
            windowTease={selectedWindowTease}
            onClose={() => {
              setSelectedFeature(null)
            }}
          />
        )}
        <MapLegend
          onlineDevices={onlineDevices}
          totalDevices={totalDevices}
          deviceStatData={deviceStatData}
          windowTeasePoints={windowTeasePoints}
          useSatellite={useSatellite}
          setUseSatellite={setUseSatellite}
        />
        <AddressLookup
          onSelect={(result) => {
            if (result) {
              mapRef.current?.getMap()?.flyTo({
                center: result.center,
                zoom: 15.5,
              })
              setAddressMarker(result)
            }
          }}
          proximity={`${defaultCoords.viewport.longitude},${defaultCoords.viewport.latitude}`}
        />
        {/*<CurbCarOverlay />*/}
      </div>
    </div>
  )
}

export default CurbMap

const getDataDateBounds = (data) => {
  if (!data) return null
  let meta = data["meta"] || {}
  let start = meta["start_at"]
  let end = meta["end_at"]
  if (start && end) {
    const startDate = new Date(start * 1000)
    const endDate = new Date(end * 1000)
    return `${format(startDate, "MMM do")} - ${format(endDate, "MMM do")}`
  }
}
