import { get, keyBy } from "lodash"
import groupBy from "lodash/groupBy"
import { useMemo } from "react"
import { useQuery } from "react-query"
import axios from "../lib/axios"
import point from "../lib/point"

const fetchCameras = async (type) => {
  const url = `/csd-api/cameras`
  const devices = await axios.get(url)
  return (
    devices.data?.cameras?.filter((c) => {
      return c.device_id !== null && c.extrinsics?.cam_lat && c.extrinsics?.cam_lon
    }) || []
  )
}

const fetchCVDDevices = async () => {
  const url = `/csd-api/devices`
  const devices = await axios.get(url)
  const now = Date.now()
  return devices.data.devices
    .map((device) => {
      const lastSeenDelta = now - new Date(device.last_seen_at).getTime()
      const isOnline = lastSeenDelta < 300000
      return {
        ...device,
        lastSeenDelta,
        isOnline,
        last_seen_at: new Date(device.last_seen_at),
      }
    })
    .filter((d) => d.environment === "production")
}

export const useCameras = () => {
  const deviceQuery = useQuery(["use-cvd-devices"], fetchCVDDevices)
  const cameraQuery = useQuery([`use-cameras`], fetchCameras)

  const deviceData = deviceQuery.data
  const cameraData = cameraQuery.data

  const cameraProps = useMemo(() => {
    const devicesById = keyBy(deviceData, "id")
    let onlineDevices = 0
    let totalDevices = 0

    const cameraPoints = (cameraData || [])
      .map((camera) => {
        const latitude = get(camera, "extrinsics.cam_lat")
        const longitude = get(camera, "extrinsics.cam_lon")
        const device = deviceData && devicesById[camera.device_id]
        if (latitude === undefined || longitude === undefined) return null
        if (device === undefined) return null
        totalDevices += 1
        if (device.isOnline) onlineDevices += 1
        return point([longitude, latitude], { ...camera, latitude, longitude, device })
      })
      .filter((c) => c)

    return {
      cameraPoints: cameraPoints,
      onlineDevices,
      totalDevices,
    }
  }, [deviceData, cameraData])
  return {
    ...cameraProps,
    isLoading: deviceQuery?.isLoading && cameraQuery?.isLoading,
  }
}

const fetchDeviceCameras = async (type) => {
  const cameras = await fetchCameras(type)
  return groupBy(cameras, "device_id")
}

export const useDeviceCameras = () => {
  const query = useQuery([`use-cameras-by-device-id`], fetchDeviceCameras)
  return query
}

export default useCameras
