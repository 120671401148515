import { Box, Text } from "@chakra-ui/react"
import React from "react"
import DeviceImage from "./DeviceImage"

export function DeviceCard({ device, images }) {
  const baseImage = images?.baseImage
  return (
    <Box p={3} boxShadow="base" borderRadius="base">
      <DeviceImage image={baseImage} />
      <Text fontSize="lg" textAlign="left">
        {device.display_name}
      </Text>
      <Text fontSize="xs">{device.address}</Text>
    </Box>
  )
}

export default DeviceCard
