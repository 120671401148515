import { Box, Grid, Stack } from "@chakra-ui/react"
import { Link, useNavigate } from "react-router-dom"
import { useCurrentUser } from "../api/useCurrentUser"
import { useDevices } from "../api/useDevices"
import Breadcrumbs from "../components/Breadcrumbs"
import DeviceCard from "../components/DeviceCard"
import LoadingScreen from "./LoadingScreen"

export function DevicesScreen() {
  const { devices, deviceImages, isLoading: devicesAreLoading } = useDevices()
  const { company } = useCurrentUser()
  const isMMS = company?.id === "3CQAHFKP"
  const navigate = useNavigate()

  const isLoading = devicesAreLoading

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isMMS) {
    navigate("/parking_utilization")
    return <LoadingScreen />
  }

  return (
    <Stack p={5}>
      <Breadcrumbs links={[{ to: "/devices", name: "All Devices", isCurrentPage: true }]} />
      <Grid gap={6} templateColumns="repeat(auto-fill, 350px)">
        {devices.map((device) => {
          return (
            <Link key={device.id} to={`/devices/${device.id}`}>
              <ScaleOnHover key={device.id}>
                <DeviceCard device={device} images={deviceImages[device.id]} />
              </ScaleOnHover>
            </Link>
          )
        })}
      </Grid>
    </Stack>
  )
}

const ScaleOnHover = ({ ...rest }) => {
  return (
    <Box
      transform="scale(1)"
      transition="transform ease-in-out 200ms"
      _hover={{
        transform: "scale(1.05)",
      }}
      {...rest}
    />
  )
}

export default DevicesScreen
