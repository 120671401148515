import { useToast } from "@chakra-ui/react"
import { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router"
import axios from "../lib/axios"

export async function fetchUserInfo() {
  const result = await axios.get("/api/v2/me")
  return result.data
}

export function useCurrentUser() {
  const { data, ...rest } = useQuery("me", fetchUserInfo)
  const user = data?.user
  const company = data?.company
  return {
    user,
    company,
    ...rest,
  }
}

export function useLoginAs() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const queryClient = useQueryClient()
  async function loginAs(company_id) {
    setLoading(true)
    try {
      await axios.post(`/api/v2/login_as/${company_id}`)
      await queryClient.refetchQueries(["me", "devices"])
      navigate("/devices")
      window.location.reload()
    } catch (e) {
      toast({
        title: "User Change Failed",
        description: e.message,
        isClosable: true,
        status: "error",
      })
    } finally {
      setLoading(false)
    }
  }

  return [loginAs, loading] as const
}
