import { Button, chakra, HStack, Stack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import TextFormControl from "./controls/TextFormControl"

export type FormData = {
  name: string
  phone: string
}

type Props = {
  onSubmit: (data: FormData) => void
  defaultValues?: Partial<FormData>
}

export default function EditProfileForm({ onSubmit, defaultValues }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues,
  })

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} w="100%">
      <Stack spacing={3} align="flex-start" maxWidth="container.md">
        <TextFormControl error={errors?.name} label="Name" {...register("name", {})} />
        <TextFormControl error={errors?.phone} label="Phone" {...register("phone", {})} />
        <HStack>
          <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
            Update Information
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  )
}
