import { Box } from "@chakra-ui/react"

function CFMarker(props) {
  return (
    <Box
      width="32px"
      height="32px"
      borderRadius="32px"
      padding="5px"
      borderStyle="solid"
      cursor="pointer"
      backgroundColor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="transform ease-in-out 200ms, border ease-in-out 200ms, box-shadow ease-in-out 200ms"
      {...props}
    />
  )
}

export default CFMarker
