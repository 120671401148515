import { Box } from "@chakra-ui/react"
import React from "react"
import Geocoder from "./Geocoder"

const AddressLookup = ({ proximity, onSelect }) => {
  return (
    <Box
      position="absolute"
      top="10px"
      left="10px"
      zIndex="1"
      sx={{
        ".geo-results": {
          backgroundColor: "white",
          padding: "10px",
          border: "solid 1px gray",
          boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
        },
        ul: {
          listStyleType: "none",
        },
        li: {
          padding: "3px",
        },
        ".geo-input": {
          fontSize: "16px",
          padding: "7px",
          width: "280px",
          borderRadius: "5px",
          border: "solid 1px gray",
        },
      }}
    >
      <Geocoder proximity={proximity} onSelect={onSelect} />
    </Box>
  )
}

export default AddressLookup
