import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import React from "react";
import { ChangeHandler, FieldError } from "react-hook-form";

type Props = {
  helpText?: string;
  label: string;
  name: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: ChangeHandler;
  error?: FieldError;
  type?: string;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
  disabled?: boolean;
};

const TextFormControl = React.forwardRef((props: Props, ref: any) => {
  const {
    helpText,
    onFocus,
    label,
    error,
    name,
    onChange,
    onBlur,
    placeholder,
    value,
    type,
    inputLeftAddon,
    inputRightAddon,
    disabled,
  } = props;
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        {inputLeftAddon && <InputLeftAddon children={inputLeftAddon} />}
        <Input
          defaultValue=""
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          value={value}
          type={type}
          disabled={disabled}
        />
        {inputRightAddon && <InputRightAddon children={inputRightAddon} />}
      </InputGroup>
      <FormErrorMessage>{error?.message || error?.type}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
});

export default TextFormControl;
