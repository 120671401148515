import { useMemo } from "react"
import { useQuery } from "react-query"
import axios from "../lib/axios"

async function fetchDeviceInfo() {
  const result = await axios.get("/api/v2/devices")
  return result.data
}

export function useDevices() {
  const { data, ...rest } = useQuery("devices", fetchDeviceInfo)
  const devices = data?.devices || []
  const deviceImages = data?.deviceImages || {}
  return {
    devices,
    deviceImages,
    ...rest,
  }
}

export function useDevicesById() {
  const { devices, ...rest } = useDevices()

  const devicesById = useMemo(() => {
    if (!devices) return {}
    const byId: { [id: string]: any } = {}
    for (const device of devices) {
      byId[device.id] = device
    }
  }, [devices])

  return { devices, devicesById, ...rest }
}

export function useDevice(deviceId: string) {
  const { devices, deviceImages, ...rest } = useDevices()
  const device = devices.find((d: any) => d.id === deviceId)
  const deviceImage = deviceImages[deviceId]
  return {
    device,
    deviceImage,
    ...rest,
  }
}
