import { Button, chakra, HStack, Stack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import TextFormControl from "./controls/TextFormControl"

export type FormData = {
  old_password: string
  password: string
  password_confirm: string
}

type Props = {
  onSubmit: (data: FormData) => void
}

export default function ChangePasswordForm({ onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({})

  const password = watch("password")

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} w="100%">
      <Stack spacing={3} align="flex-start" maxWidth="container.md">
        <TextFormControl
          error={errors?.old_password}
          label="Old Password"
          {...register("old_password", {
            required: true,
          })}
        />
        <TextFormControl
          error={errors?.password}
          label="New Password"
          {...register("password", {
            required: true,
          })}
        />
        <TextFormControl
          error={errors?.password_confirm}
          label="Confirm new Password"
          {...register("password_confirm", {
            required: true,
            validate: (value) => {
              return value === password || "Passwords do not match"
            },
          })}
        />
        <HStack>
          <Button type="submit" colorScheme="blue" disabled={isSubmitting}>
            Update Information
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  )
}
