import {
  Box,
  Checkbox,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import { ApexOptions } from "apexcharts"
import { addHours, startOfDay } from "date-fns"
import { cloneDeep, sum } from "lodash"
import { useMemo, useState } from "react"
import Chart from "react-apexcharts"
import { useCurrentUser } from "../api/useCurrentUser"
import { useParkingEvents } from "../api/useParkingEvents"
import { DateRangePicker } from "../components/DateRangePicker"
import HelpForm from "../components/HelpForm"
import LoadingSpinner from "../components/LoadingSpinner"
import SelectFormControl from "../forms/controls/SelectFormControl"
import LoadingScreen from "./LoadingScreen"

const options = {
  colors: ["#F7E75F", "#55845B", "#FF8C42", "#FF3C38", "#6C8EAD", "#333987"],

  chart: {
    type: "rangeBar",
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "dd MMM",
        hour: "hh:mm tt",
      },
      style: {
        fontSize: "16px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "16px",
      },
    },
  },
  tooltip: {
    x: {
      format: "MM/dd hh:mm tt",
    },
  },
} as ApexOptions

const InitialSeriesData = {
  brightline_tesla: {
    name: "Brightline Tesla",
    data: [],
  },
  brightline_via_van: {
    name: "Brightline Via Van",
    data: [],
  },
  brightline_fixed_route_shuttle: {
    name: "Brightline Fixed Route Shuttle",
    data: [],
  },
  brightline_gem_golfcart: {
    name: "Brightline Gem Golfcart",
    data: [],
  },
  normal_car_or_van: {
    name: "Car or Van",
    data: [],
  },
  unknown: {
    name: "Other",
    data: [],
  },
  occupied: {
    name: "Occupied",
    data: [],
  },
} as any

export function ParkingScreenTimeline() {
  const { user, company, isLoading } = useCurrentUser()
  if (isLoading) return <LoadingScreen />

  const dashboardEnabled = ["3CQAHFKP", "1JAPBK3U","U9ACJ25D"].includes(company?.id)
  const isBrightline = company?.id === "1JAPBK3U"

  if (dashboardEnabled) {
    return (
      <Stack p={5}>
        <Tabs defaultIndex={0} isLazy lazyBehavior="keepMounted">
          <TabList>
            {!isBrightline && <Tab>Chart View</Tab>}
            <Tab>Timeline View</Tab>
          </TabList>

          <TabPanels>
            {!isBrightline && (
              <TabPanel px={0} py={1}>
                <explo-dashboard
                  dash-user-group-token={`aBAMVVZYkv:${company.user_group_token}`}
                  isProduction="true"
                  environment="production"
                ></explo-dashboard>
              </TabPanel>
            )}
            <TabPanel>
              <ParkingUtilizatoinDashboard />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    )
  } else {
    return (
      <Stack
        p={5}
        backgroundImage={
          "linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('/images/action-gif-low.gif')"
        }
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        height="100%"
      >
        <Container
          backgroundColor="#FAFAFA"
          centerContent
          padding="8"
          borderRadius="25px"
          width="unset"
        >
          <HelpForm
            title="Learn more about Parking Events"
            description={
              <Box>
                <Text>
                  Contact us to discuss using curbFlow to track legal vs illegal parking events at
                  your locations
                </Text>
              </Box>
            }
            helpContext="curbCar"
            defaultValues={{
              message: `I'd like to learn more about parking event tracking from curbFlow.`,
              email: user?.email,
              phone: user?.phone,
            }}
          />
        </Container>
      </Stack>
    )
  }
}

// const currentTimezone = "US/Eastern"

export function ParkingUtilizatoinDashboard() {
  const today = startOfDay(new Date())
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const { isLoading, company } = useCurrentUser()
  const [utilizationOnlyState, setUtilizationOnlyState] = useState(false)
  const companyId = company.id
  const isBrightline = companyId === "1JAPBK3U"
  const initialParkingLot = Object.keys(SPOT_MAPS[companyId])[0]
  const [selectedParkingLot, setSelectedParkingLot] = useState(initialParkingLot)
  const utilizationOnly = isBrightline ? utilizationOnlyState : true
  const initialStart = addHours(yesterday, 9)
  const initialEnd = addHours(yesterday, 17)
  const [queryParams, setQueryParms] = useState({
    startDate: initialStart,
    endDate: initialEnd,
  })
  const peQuery = useParkingEvents(queryParams.startDate, queryParams.endDate)
  const data = peQuery.data
  const selectedSpots = SPOT_MAPS[companyId][selectedParkingLot]

  const { chartData, zones, stats } = useMemo(() => {
    let chartData = cloneDeep(InitialSeriesData)

    if (!data || !selectedSpots || data.length === 0) {
      return { chartData, zones: new Set() }
    }

    let zones = new Set()
    let counter = new Counter()

    for (const event of data) {
      // const start = new Date(parseInt(event["parking_start_at"], 10))
      // const end = new Date(parseInt(event["parking_end_at"], 10))

      // if (end.getTime() - start.getTime() < 1000 * 500) {
      //   continue //skip events that are too short
      // }

      let vehicle_class = (event["vehicle_class"] || "unknown").toLowerCase()

      if (vehicle_class === "normal_car") {
        vehicle_class = "normal_car_or_van"
      }

      if (!(vehicle_class in InitialSeriesData)) {
        vehicle_class = "unknown"
      }

      if (utilizationOnly) {
        vehicle_class = "occupied"
      }

      const zone = mapZoneToSpotLabel(event["zone"], selectedSpots)

      if (zone) {
        zones.add(event["zone"])
        counter.add({
          zone: zone,
          vehicle_class: InitialSeriesData[vehicle_class]["name"],
          time: event["parking_end_at"] - event["parking_start_at"],
        })

        chartData[vehicle_class]["data"].push({
          x: zone,
          y: [event["parking_start_at"], event["parking_end_at"]],
        })
      }
    }

    if (utilizationOnly) {
      chartData = {
        ...[chartData["occupied"]],
      }
    } else {
      delete chartData["occupied"]
    }
    return { chartData, zones, stats: counter.stats() } as const
  }, [data, utilizationOnly, selectedSpots])

  if (isLoading) return <LoadingScreen />

  const parkingLotOptions = Object.keys(SPOT_MAPS[companyId]).map((v) => {
    return { label: LOCATION_LABELS[v], value: v }
  })

  const chartOptions = {
    ...options,
    xaxis: {
      ...options.xaxis,
      categories: Object.values(selectedSpots),
    },
    colors: utilizationOnly ? ["#333987"] : options.colors,
  }

  const series = Object.values(chartData) as any
  const rowHeight = utilizationOnly ? 45 : 60
  const chartHeight = Math.max(zones.size * rowHeight, 400)
  return (
    <Box padding="2">
      <Heading marginBottom="0">Parking Utilization</Heading>
      <Text marginBottom="2">
        All dates & times are display in the '{Intl.DateTimeFormat().resolvedOptions().timeZone}'
        timezone.
      </Text>

      <Box>
        <DateRangePicker
          initialStart={initialStart}
          initialEnd={initialEnd}
          onChange={setQueryParms}
        />
        <br />
        <Box maxWidth={450}>
          <SelectFormControl
            label="Viewing Parking Lot"
            options={parkingLotOptions}
            onChange={(e) => {
              setSelectedParkingLot(e.target.value)
            }}
            name="seletecedParkingLot"
          />
        </Box>
        {isBrightline && (
          <Box display="flex" flexDirection="row" alignItems="center" py="3">
            <Checkbox
              checked={utilizationOnly}
              onChange={(e) => {
                setUtilizationOnlyState(e.target.checked)
              }}
            />
            <Text marginLeft="4" lineHeight={0}>
              Show Utilization Only
            </Text>
          </Box>
        )}
      </Box>

      {peQuery.isLoading && <LoadingSpinner />}
      {!peQuery.isLoading && (
        <Box>
          <Heading fontSize={24} marginTop="4">
            Parking Spot Utilization: {selectedParkingLot}
          </Heading>
          <Chart options={chartOptions} series={series} type="rangeBar" height={chartHeight} />
        </Box>
      )}
      <Text textAlign="center">
        parking events that extend beyond time ranges shown in totality
      </Text>
      <Stats stats={stats} utilizationOnly={utilizationOnly} />
    </Box>
  )
}

function timeToHours(time: number) {
  return (time / 1000 / 60 / 60).toFixed(1)
}

const Stats = ({ stats, utilizationOnly }: { stats: ParkingStats; utilizationOnly: boolean }) => {
  if (!stats) return null
  const clsKeys = Object.keys(stats.countsByClass).sort()
  const zoneKeys = Object.keys(stats.countsByZone).sort((a, b) => {
    const anum = parseInt(a.replaceAll(/[^0-9]/gi, ""))
    const bnum = parseInt(b.replaceAll(/[^0-9]/gi, ""))
    return anum - bnum
  })

  return (
    <Box mt="3">
      <Heading mb="3">Stats</Heading>
      <SimpleGrid columns={5} spacing={5}>
        <Box borderWidth={1} maxW="xs" p={4} borderRadius="lg">
          <Stat>
            <StatLabel>Total Parking Events</StatLabel>
            <StatNumber>{stats.totalCount}</StatNumber>
          </Stat>
        </Box>
        <Box borderWidth={1} maxW="xs" p={4} borderRadius="lg">
          <Stat>
            <StatLabel>Total Parked Time</StatLabel>
            <StatNumber>{timeToHours(stats.totalTime)} (hrs)</StatNumber>
          </Stat>
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={2} spacing={10} maxW={1400}>
        {!utilizationOnly && (
          <Box>
            <Heading size="lg" mt="5">
              Stats by Vehicle Class
            </Heading>
            <Box marginTop="5">
              <SimpleGrid columns={2} spacing={3}>
                {clsKeys.map((cls) => {
                  return (
                    <>
                      <Box borderWidth={1} maxW="xs" p={4} borderRadius="lg">
                        <Stat>
                          <StatLabel>{cls} Events</StatLabel>
                          <StatNumber>{stats.countsByClass[cls]}</StatNumber>
                        </Stat>
                      </Box>
                      <Box borderWidth={1} maxW="xs" p={4} borderRadius="lg">
                        <Stat>
                          <StatLabel>{cls} Time</StatLabel>
                          <StatNumber>{timeToHours(stats.timesByClass[cls])} (hrs)</StatNumber>
                        </Stat>
                      </Box>
                    </>
                  )
                })}
              </SimpleGrid>
            </Box>
          </Box>
        )}

        <Box>
          <Heading size="lg" mt="5">
            Stats by Zone
          </Heading>
          <Box marginTop="5">
            <SimpleGrid columns={2} spacing={3}>
              {zoneKeys.map((zone) => {
                return (
                  <>
                    <Box borderWidth={1} maxW="xs" p={4} borderRadius="lg">
                      <Stat>
                        <StatLabel>{zone} Events</StatLabel>
                        <StatNumber>{stats.countsByZone[zone]}</StatNumber>
                      </Stat>
                    </Box>
                    <Box borderWidth={1} maxW="xs" p={4} borderRadius="lg">
                      <Stat>
                        <StatLabel>{zone} Time</StatLabel>
                        <StatNumber>{timeToHours(stats.timesByZone[zone])} (hrs)</StatNumber>
                      </Stat>
                    </Box>
                  </>
                )
              })}
            </SimpleGrid>
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  )
}

const WPB_SPOTS = {
  "wpb spot 1": "WPB Spot 1",
  "wpb spot 2": "WPB Spot 2",
  "wpb spot 3": "WPB Spot 3",
  "wpb spot 4": "WPB Spot 4",
  "wpb spot 5": "WPB Spot 5",
  "wpb spot 6": "WPB Spot 6",
}

const MIA_SPOTS = {
  "mia space 1": "MIA Spot 1",
  "mia space 2": "MIA Spot 2",
  "miami spot 3": "MIA Spot 3",
  "miami spot 4": "MIA Spot 4",
  "miami spot 5": "MIA Spot 5",
  "miami zone 6": "MIA Spot 6",
  "miami spot 7": "MIA Spot 7",
  "miami zone 8": "MIA Spot 8",
  "miami spot 9": "MIA Spot 9",
  "miami spot 10": "MIA Spot 10",
  "miami spot 11": "MIA Spot 11",
  "miami spot 12": "MIA Spot 12",
  "miami spot 13": "MIA Spot 13",
  "miami spot 14": "MIA Spot 14",
}

const MM_SPOTS = {
  "parking zone #1": "Parking Zone #1",
  "parking zone #2": "Parking Zone #2",
  "parking zone #3": "Parking Zone #3",
  "parking zone #4": "Parking Zone #4",
  "parking zone #5": "Parking Zone #5",
  "parking zone #6": "Parking Zone #6",
  "parking zone #7": "Parking Zone #7",
  "parking zone #8": "Parking Zone #8",
  "parking zone #9": "Parking Zone #9",
  "parking zone #10": "Parking Zone #10",
  "parking zone #11": "Parking Zone #11",
  "parking zone #12": "Parking Zone #12",
  "parking zone #13": "Parking Zone #13",
  "parking zone #14": "Parking Zone #14",
  "parking zone #15": "Parking Zone #15",
}

const SPOT_MAPS = {
  "1JAPBK3U": {
    WPB: WPB_SPOTS,
    MIA: MIA_SPOTS,
  },
  "3CQAHFKP": {
    "MANHATTAN MINI": MM_SPOTS,
  },
  "U9ACJ25D": {
    "MANHATTAN MINI": MM_SPOTS,
  }
}

const LOCATION_LABELS = {
  WPB: "WPB",
  MIA: "MIA",
  "MANHATTAN MINI": "Manhattan Mini — 645 W 44th St",
}

function mapZoneToSpotLabel(zone: string, spotList: { [v: string]: string }) {
  const zoneLower = zone.toLowerCase().trim()
  if (spotList[zoneLower]) {
    return spotList[zoneLower]
  } else {
    return null
  }
}

export default ParkingScreenTimeline

type CounterEvent = {
  vehicle_class: string
  time: number
  zone: string
}

type ParkingStats = {
  totalCount: number
  totalTime: number
  countsByClass: { [key: string]: number }
  countsByZone: { [key: string]: number }
  timesByClass: { [key: string]: number }
  timesByZone: { [key: string]: number }
}
class Counter {
  countsByClass: { [key: string]: number }
  countsByZone: { [key: string]: number }
  timesByClass: { [key: string]: number }
  timesByZone: { [key: string]: number }

  constructor() {
    this.countsByClass = {}
    this.countsByZone = {}
    this.timesByClass = {}
    this.timesByZone = {}
  }

  add(event: CounterEvent) {
    const cls = event.vehicle_class
    const time = event.time
    const zone = event.zone

    if (this.countsByClass[cls]) {
      this.countsByClass[cls] += 1
    } else {
      this.countsByClass[cls] = 1
    }

    if (this.countsByZone[zone]) {
      this.countsByZone[zone] += 1
    } else {
      this.countsByZone[zone] = 1
    }

    if (this.timesByClass[cls]) {
      this.timesByClass[cls] += time
    } else {
      this.timesByClass[cls] = time
    }

    if (this.timesByZone[zone]) {
      this.timesByZone[zone] += time
    } else {
      this.timesByZone[zone] = time
    }
  }

  stats(): ParkingStats {
    const totalCount = sum(Object.values(this.countsByClass))
    const totalTime = sum(Object.values(this.timesByClass))

    return {
      totalCount: totalCount || 0,
      totalTime: totalTime || 0,
      countsByClass: this.countsByClass || {},
      countsByZone: this.countsByZone || {},
      timesByClass: this.timesByClass || {},
      timesByZone: this.timesByZone || {},
    }
  }
}
