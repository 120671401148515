import { useQuery } from "react-query"
import axios from "axios"
import point from "../lib/point"
import { get } from "lodash"
import { useMemo } from "react"

const fetchStats = async () => {
  const result = await axios.get(
    "https://s3.amazonaws.com/apps.curbflow.com/window_tease/data.json"
  )
  return result.data
}

const useWindowTease = () => {
  const query = useQuery([`use-window-tease`], fetchStats)
  return query
}

const EmptyArray = []
export const useWindowTeasePoints = () => {
  const { data } = useWindowTease()
  const windowTeaseData = data || EmptyArray

  const points = useMemo(() => {
    return windowTeaseData.map((d) => {
      const latitude = get(d, "fields.Latitude")
      const longitude = get(d, "fields.Longitude")
      return point([longitude, latitude], { ...d.fields, type: "Window Tease" })
    })
  }, [windowTeaseData])
  return points
}

export default useWindowTeasePoints
