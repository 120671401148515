import "mapbox-gl/dist/mapbox-gl.css"
import React, { useState } from "react"
import { Button, IconButton } from "@chakra-ui/react"
import { useHelpContext } from "../components/HelpModal"
import { CloseIcon } from "@chakra-ui/icons"

const SelectedCamera = ({
  selectedDevice,
  selectedStats,
  statsLastUpdated,
  onClose,
  dateBounds,
}) => {
  const helpContext = useHelpContext()
  const [maxWidth, setMaxWidth] = useState(320)

  return (
    <div
      style={{
        border: "solid 1px darkgray",
        padding: 14,
        paddingTop: 25,
        position: "absolute",
        right: 25,
        top: 10,
        backgroundColor: "white",
        borderRadius: 4,
        maxWidth: maxWidth,
        boxShadow: "0 0 10px 2px rgb(0 0 0 / 10%)",
      }}
    >
      <h5>{selectedDevice?.address || selectedDevice.display_name}</h5>
      {!selectedDevice.isOnline && (
        <div
          style={{
            padding: 10,
            backgroundColor: "#efefef",
            border: "solid 1px gray",
            borderRadius: 5,
            marginBottom: "0.5em",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>Temporarily Offline</p>
          <p style={{ fontSize: 12, margin: 0 }}>
            last seen: {selectedDevice.last_seen_at.toLocaleString()}
          </p>
        </div>
      )}
      <b>Last 7 Days</b>
      <p style={{ fontSize: 12 }}>{dateBounds}</p>
      <p>
        Pedestrians: <b>{selectedStats.sums?.new_pedestrian_count?.toLocaleString()}</b>
        <br />
        Vehicles: <b>{selectedStats.sums?.new_vehicle_count?.toLocaleString()}</b>
        <br />
        Online Percentage:{" "}
        <b>{getOnlinePercentage(selectedStats.means?.uptime_percent_estimate)}</b>
      </p>

      <p style={{ fontSize: 12, marginBottom: 0 }}>Last Updated: {statsLastUpdated}</p>
      <Button
        style={{ marginTop: 10 }}
        onClick={() => {
          helpContext.showModal("upgrade")
        }}
      >
        See More Data
      </Button>
      <div style={{ textAlign: "center" }}>
        <img
          src={`https://s3.amazonaws.com/apps.curbflow.com/cvd_images/${selectedDevice.id}.jpg`}
          alt="view from location"
          style={{ width: "100%", marginTop: 20, cursor: "pointer" }}
          onClick={() => {
            if (maxWidth <= 320) {
              setMaxWidth(680)
            } else {
              setMaxWidth(320)
            }
          }}
        />
      </div>
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="xs"
        style={{
          position: "absolute",
          top: 2,
          right: 2,
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}

const getOnlinePercentage = (value) => {
  if (value > 95) {
    return "100%"
  } else if (value > 10) {
    return (Math.round(value / 10) * 10).toFixed(0) + "%"
  } else {
    return "0%"
  }
}

export default SelectedCamera
