// TODO this is how I have setup a global color palette + predefined color "tokens" that can
// be used in various component definitions in the past. For this project, I feel like our styles are not super
// well defined or consistent yet, so this structure may or may not be overkill at this point.

// At a high level, my thinking was to define the base "palette" of colors that everything in the app should
// be working off of in the "palette" variable. I'd then define app "tokens" in the app variable for things
// like text color, button color, border colors, etc..., which would all be references to values in the palette.

// But again, this was from when I was working with some designers who had spent a lot of time and effort on their
// color schemes and we wanted to make sure that everything precisely as they had defined it. This might be too much
// structure for this project. Maybe we just need a flat export of colors or something like that, idk.

import color from "color"

const palette = {
  grays: {
    dark: "#212529",
    lightest: "#FEFEFE",
    lighter: "#EEEEEE",
    light: "#DEDEDE",
    medium: "#CACACA",
  },
  blue: {
    light: "#EFFAFF",
    main: "#54b8e1",
    dark: color("#54b8e1").darken(0.1).rgb().string(),
    darker: color("#54b8e1").darken(0.2).rgb().string(),
  },
  darkBlue: {
    main: "#0A469E",
    dark: color("#0A469E").darken(0.15).rgb().string(),
    darker: color("#0A469E").darken(0.3).rgb().string(),
  },
}

const app = {
  navBackground: palette.darkBlue.main,
  background: "white",
  textColor: "#2E384D",
  bodyText: "white",
  blueBg: "#53B8E0",
  text: {
    main: "#2E384D",
    light: "#8798AD",
  },
  controlBackground: palette.grays.lighter,
  controlBorder: palette.grays.medium,
  control: {
    main: palette.darkBlue.main,
    hover: palette.darkBlue.dark,
    active: palette.darkBlue.darker,
    disabled: palette.darkBlue.main,
  },
}

const colors = {
  ...app,
  palette,
}

export default colors
