import { Marker } from "@urbica/react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import { colors } from "./styles"
import { BsFillCameraVideoFill } from "react-icons/bs"
import CFMarker from "./CFMarker"

function CFWindowTeaseMarker({ longitude, latitude, onClick, isSelected }) {
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <CFMarker
        onClick={onClick}
        borderColor={colors.palette.blue.main}
        sx={{
          borderWidth: isSelected ? 3 : 2,
          boxShadow: isSelected ? "3px 3px 3px 1px rgba(0, 0, 0, 0.4)" : "none",
          transform: isSelected ? "scale(1.5)" : "scale(1)",
        }}
      >
        <BsFillCameraVideoFill color={colors.palette.blue.main} size={14} />
      </CFMarker>
    </Marker>
  )
}

export default CFWindowTeaseMarker
