import { Box, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useCurrentUser } from "../api/useCurrentUser"
import HelpForm from "./HelpForm"

const HelpContext = React.createContext<any>(null)

const helpChannels = {
  general: {
    name: "general",
    defaultMessage: "",
    slackChannel: "curbmap-support",
    title: "Contact Support",
    description: "How can we help you?",
  },
  curbCar: {
    name: "curbCar",
    defaultMessage:
      "Hi!\n\nI have questions about curbCar or would like to join your Waitlist. Can you please contact me to discuss?",
    slackChannel: "curbcar-support",
    title: "Learn About curbCar",
    description:
      "curbCar is the first on-demand data collection platform available to anyone. We have outfitted small cars with roof rigs equipped with our cameras, air quality and decibel meters.\n\nBeginning November 2021, curbCar is available to be dispatched with a day's notice anywhere in the DMV and NY metropolitan areas to collect data for 1-7 days at a time.\n\nIf you have questions or would like to join our Waitlist, let us know below!",
  },
  upgrade: {
    name: "upgrade",
    defaultMessage:
      "Hi!\n\nI'm interested in upgrading my account to get access to more detailed information about specific locations.",
    slackChannel: "curbmap-support",
    title: "Contact Support",
    description: "How can we help you?",
  },
}

export const HelpContextProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [helpChannel, setHelpChannel] = useState(helpChannels["general"])

  const showModal = useCallback((channel = "general") => {
    setHelpChannel(helpChannels[channel] || helpChannels["general"])
    setShow(true)
  }, [])

  return (
    <HelpContext.Provider value={{ show, setShow, showModal, helpChannel }}>
      <>
        {children}
        <HelpModal />
      </>
    </HelpContext.Provider>
  )
}

export const useHelpContext = () => {
  return React.useContext(HelpContext)
}

const HelpModal = ({ ...rest }) => {
  const helpContext = useHelpContext()
  const { user } = useCurrentUser()
  const { setShow, helpChannel } = helpContext

  return (
    <Modal
      isOpen={helpContext.show}
      onClose={() => {
        setShow(false)
      }}
      key={[user && user.id, helpChannel.name].join("-")}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box p={3}>
            <HelpForm
              title={helpChannel.title}
              description={helpChannel.description}
              slackChannel={helpChannel.slackChannel}
              defaultValues={{
                message: helpChannel.defaultMessage,
                email: user?.email,
                phone: user?.phone,
              }}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default HelpModal
