import { useQuery } from "react-query"
import axios from "../lib/axios"

async function fetchCompanyInfo() {
  const result = await axios.get("/api/v2/companies")
  return result.data
}

export function useCompanies() {
  const { data, ...rest } = useQuery("all-companies", fetchCompanyInfo)
  const companies = data?.companies || []
  const devicesForCompany = data?.devicesForCompany || {}
  return {
    companies,
    devicesForCompany,
    ...rest,
  }
}

export default useCompanies
