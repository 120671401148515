import { useQuery } from "react-query"
import axios from "../lib/axios"

async function fetchParkingEvents({ queryKey }) {
  const [, { startDate, endDate }] = queryKey

  const startTime = startDate.getTime()
  const endTime = endDate.getTime()
  const result = await axios.get(`/api/v2/parking_events?start=${startTime}&end=${endTime}`)
  return result.data
}

export function useParkingEvents(startDate, endDate) {
  const query = useQuery(["parkingEvents", { startDate, endDate }], fetchParkingEvents)
  return query
}
