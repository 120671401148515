import { Box } from "@chakra-ui/react"
import React from "react"

export function LinkStyledButton(props) {
  return (
    <Box
      as="button"
      height="1em"
      color="blue.500"
      _hover={{
        textDecoration: "underline",
        color: "blue.600",
      }}
      _active={{
        color: "blue.600",
      }}
      _focus={{
        outlineColor: "blue.100",
        outlineOffset: 4,
      }}
      {...props}
    ></Box>
  )
}

export default LinkStyledButton
