import {
  Box,
  Container,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react"
import React, { useCallback, useEffect } from "react"
import HelpForm from "../components/HelpForm"
import { useCurrentUser } from "../api/useCurrentUser"
import { doorCountDescription } from "../lib/constants"
import LoadingScreen from "./LoadingScreen"

export function DoorCountsScreen() {
  const { user, company, isLoading: userLoading } = useCurrentUser()
  const isDesktop = useBreakpointValue([false, null, null, true])

  if (userLoading) {
    return <LoadingScreen />
  }

  const doorCountsEnabled = ["HNG0VGRJ", "AJ84V8GU", "AT4WQGEK"].includes(company?.id)
  const backgroundImage = isDesktop
    ? "/images/desktop-doorcounts-blur.jpg"
    : "/images/mobile-doorcounts-blur.jpg"

  if (doorCountsEnabled) {
    return (
      <Stack p={5}>
        <DoorCountDashboard company={company} />
      </Stack>
    )
  } else {
    return (
      <Stack
        p={5}
        backgroundImage={
          "linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('" +
          backgroundImage +
          "')"
        }
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        height="100%"
      >
        <Container
          backgroundColor="#FAFAFA"
          centerContent
          padding="8"
          borderRadius="25px"
          width="unset"
        >
          <HelpForm
            title="Upgrade needed!"
            description={doorCountDescription}
            defaultValues={{
              message: `I'd like to learn more about enabling Door Counts on my devices.`,
              email: user?.email,
              phone: user?.phone,
            }}
            helpContext="Door Counts Upgrade"
          />
        </Container>
      </Stack>
    )
  }
}

function DoorCountDashboard({ company }) {
  // Explo charts sometimes don't resize properly when inside of tabs
  // Forcing a resize event will let them fix themselves
  const triggerResize = useCallback((time = 20) => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, time)
  }, [])

  useEffect(() => {
    triggerResize()
  }, [triggerResize])

  return (
    <Tabs defaultIndex={0} p={0} onChange={triggerResize} isLazy lazyBehavior="keepMounted">
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Comparison</Tab>
        <Tab>History</Tab>
      </TabList>
      <TabPanels p={0}>
        <TabPanel px={0} py={1}>
          <Heading>Overview</Heading>
          <explo-dashboard
            dash-user-group-token={`5EbY2GexyD:${company.user_group_token}`}
            isProduction="true"
            environment="production"
            style={{ minWidth: 600 }}
          ></explo-dashboard>
        </TabPanel>
        <TabPanel px={0} py={1}>
          <Heading>Historical Comparisons</Heading>
          <explo-dashboard
            dash-user-group-token={`n38xvr9x2j:${company.user_group_token}`}
            isProduction="true"
            environment="production"
            style={{ minWidth: 600 }}
          ></explo-dashboard>
        </TabPanel>
        <TabPanel px={0} py={1}>
          <Heading>Historicals</Heading>
          <explo-dashboard
            dash-user-group-token={`jQgYw3mYvR:${company.user_group_token}`}
            isProduction="true"
            environment="production"
            style={{ minWidth: 600 }}
          ></explo-dashboard>
        </TabPanel>
      </TabPanels>
      <Box></Box>
    </Tabs>
  )
}

export default DoorCountsScreen
