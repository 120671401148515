import { Marker } from "@urbica/react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"

function CFClusterMarker({ longitude, latitude, pointCount, onlineCount = 1, onClick }) {
  let color = "rgb(0,200,0)"
  if (onlineCount === 0) {
    color = "rgb(200,200,200)"
  }

  return (
    <Marker longitude={longitude} latitude={latitude}>
      <div
        onClick={onClick}
        style={{
          width: 42,
          height: 42,
          borderRadius: 20,
          cursor: "pointer",
          backgroundColor: "white",
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: color,
          color: color,
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 16,
        }}
      >
        {pointCount}
      </div>
    </Marker>
  )
}

export default CFClusterMarker
