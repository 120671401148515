export const doorCountDescription = `
Track Ingress and Egress + Capture Rate for your locations today.
`

export const dvrDescription = `
Gain access to 30 days of video footage from your location today.
`

export const staffEngagementDescription = `
Measure how your staff engages with customers today.
`
