import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { HelpContextProvider } from "../components/HelpModal"
import MainNav from "./MainNav"

function AppLayout({ children }) {
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure()
  const showNav = useBreakpointValue([false, null, null, true])
  const templateColumns = [`1fr`, null, null, `210px 1fr`]
  const templateAreas = [`"header" "content"`, null, null, `"header header" "nav content"`]

  return (
    <Grid
      w="100%"
      h="100vh"
      templateAreas={templateAreas}
      templateColumns={templateColumns}
      templateRows={`60px 1fr`}
      gap={0}
    >
      {showNav && (
        <GridItem gridArea="nav" overflow="auto">
          <MainNav />
        </GridItem>
      )}
      {!showNav && (
        <Drawer isOpen={isMenuOpen} placement="left" onClose={onMenuClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <MainNav onClick={onMenuClose} />
          </DrawerContent>
        </Drawer>
      )}
      <GridItem gridArea="header" overflow="hidden">
        <Flex
          align="center"
          justify="flex-start"
          h="100%"
          pl={2}
          pr={2}
          bg="white"
          borderStyle="solid"
          borderBottomWidth="1px"
          borderColor="gray.500"
          boxSizing="border-box"
        >
          {!showNav && (
            <Flex flex={1}>
              <IconButton aria-label="Toggle Nav Menu" onClick={onMenuOpen}>
                <HamburgerIcon />
              </IconButton>
            </Flex>
          )}
          <Flex flex={1}>
            <Image m={2} h="45px" src="/cf-logo.svg" />
          </Flex>
          <Flex flex={1} />
        </Flex>
      </GridItem>
      <GridItem gridArea="content" overflow="auto">
        <HelpContextProvider>{children}</HelpContextProvider>
      </GridItem>
    </Grid>
  )
}

export default AppLayout
