import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import axios from "axios"
import React, { useCallback, useRef, useState } from "react"
import { useCurrentUser } from "../api/useCurrentUser"

type Props = {
  defaultValues?: {
    message?: string
    email?: string
    phone?: string
  }
  slackChannel?: string
  helpContext?: string
  title?: string
  description?: string | React.ReactNode
}

const helpModalSubmitUrl = "https://hooks.zapier.com/hooks/catch/4127496/bmnn6m9"

export function HelpForm(props: Props) {
  const { user } = useCurrentUser()
  const { title, description, defaultValues } = props
  const [error, setError] = useState<string>(null)
  const toast = useToast()
  const messageRef = useRef<any>(null)
  const phoneRef = useRef<any>(null)
  const emailRef = useRef<any>(null)
  const helpContext = props.helpContext || "general"
  const slackChannel = props.slackChannel || "customer-portal-support"

  const validateAndSend = useCallback(async () => {
    const message = messageRef?.current?.value
    const phone = phoneRef?.current?.value
    const email = emailRef?.current?.value
    if (message && message.length > 1) {
      await axios.post(
        helpModalSubmitUrl,
        JSON.stringify({
          message,
          phone,
          email: email || user.email,
          name: user.name,
          memberId: user.id,
          slackChannel,
          helpContext,
        }),
        {
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              delete headers.post["Content-Type"]
              return data
            },
          ],
        }
      )
      toast({
        description: "Thanks for sending us a note. We'll be in touch with you promptly!",
        isClosable: true,
      })
    } else {
      setError("A message is required")
    }
  }, [user, phoneRef, messageRef, emailRef, toast, helpContext, slackChannel])

  return (
    <Stack>
      {title && (
        <Heading fontWeight="300" color="blue.700">
          {title}
        </Heading>
      )}
      {React.isValidElement(description) ? description : <Text>{description}</Text>}
      <FormControl id="message">
        <FormLabel>Your Message</FormLabel>
        <Textarea
          defaultValue={defaultValues?.message || ""}
          rows={2}
          ref={messageRef}
        />
      </FormControl>
      <FormControl id="email">
        <FormLabel>Email</FormLabel>
        <Input type="email" defaultValue={defaultValues?.email || ""} ref={emailRef} />
      </FormControl>
      <FormControl id="phone">
        <FormLabel>Phone</FormLabel>
        <Input type="phone" defaultValue={defaultValues?.phone || ""} ref={phoneRef} />
      </FormControl>
      {error && <Text color="red.500">{error}</Text>}
      <Button onClick={validateAndSend} colorScheme="blue" >Submit</Button>
    </Stack>
  )
}

export default HelpForm
