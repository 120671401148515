import { useRadio, useRadioGroup, Box, HStack } from "@chakra-ui/react"
import { nanoid } from "nanoid"
import React, { useMemo } from "react"

function ToggleButton(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        boxShadow="md"
        _checked={{
          bg: "blue.500",
          color: "white",
          borderColor: "blue.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  )
}

type Option = {
  label: React.ReactNode
  value: string
}
type Props = {
  name?: string
  options: Option[]
  value?: string
  defaultValue?: string
  onChange?: (value: any) => void
}

export function ToggleButtonGroup({ name, options, defaultValue, value, onChange }: Props) {
  const inputName = useMemo(() => {
    return name || `radio-${nanoid()}`
  }, [name])

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: inputName,
    defaultValue,
    onChange,
    value,
  })

  const group = getRootProps()

  return (
    <HStack {...group} spacing={0}>
      {options.map((option) => {
        const radio = getRadioProps({ value: option.value })
        return (
          <ToggleButton key={option.value} {...radio}>
            {option.label}
          </ToggleButton>
        )
      })}
    </HStack>
  )
}
