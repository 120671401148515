import { Flex, Stack } from "@chakra-ui/react"
import React from "react"
import { ToggleButtonGroup } from "../components/ToggleButtons"
import CurbMap from "../curbMap/CurbMap"
import defaultCoords from "../lib/defaultCoords"
import useLocalStorage from "../lib/useLocalStorage"

const cityOptions = [
  { label: "NYC", value: "nyc" },
  { label: "DC", value: "dc" },
]

export function CurbmapScreen() {
  const [selectedCity, setSelectedCity] = useLocalStorage("selected-city", "nyc")

  return (
    <Stack p={0} height={"100%"}>
      <Flex paddingTop={2} textAlign="center" justify="center" align="center">
        <ToggleButtonGroup options={cityOptions} value={selectedCity} onChange={setSelectedCity} />
      </Flex>
      <CurbMap key={selectedCity} defaultCoords={defaultCoords[selectedCity]} />
    </Stack>
  )
}

export default CurbmapScreen
